import { Component, Vue } from "vue-property-decorator";

import { Header, Footer } from "@/layout";
import { StudentModel } from "@/models";
import { TeCommon } from "../Common";

export class SearchForm {
  name = '';
  phone = '';
  sid = '';
  cid = '';
}

type response = { [key: string]: unknown };

@Component<ApTeacherStudentIndexController>({
  components: {
    Header,Footer
  }
})
export default class ApTeacherStudentIndexController extends Vue {
  private doubleClick = false;
  private searchForm = new SearchForm();
  private cityList: object = {};
  private schoolList: object = {};
  private studentList: object = {};
  private num = 0;
  private page = 1;
  private perpage = 50;
  private maxPage = 0;
  private stid = 0;
  private tid = window.localStorage.getItem('teacher_tid') as string;
  private token = window.localStorage.getItem('teacherToken') as string;

  public async created() {
    this.cityList = await TeCommon.getTECity(this.tid); //取得該老師所屬班級的所有縣市
    this.getStudentList();
  }

  //取得該老師所屬班級之該縣市分校列表
  private async getTESchool(cid: string) {
    this.searchForm.sid = '';
    this.schoolList = await TeCommon.getTESchool(cid, this.tid);
  }

  public async getStudentList() {
    const item = {
      page: this.page,
      perpage: this.perpage,
      phone: this.searchForm.phone,
      name: this.searchForm.name,
      sid: this.searchForm.sid,
      cid: this.searchForm.cid,
      tid: this.tid,
      token: this.token,
    }
    const data = await StudentModel.teGetStudentList(item) as response;
    TeCommon.checkToken(data.ERR_CODE as number);
    this.num = data.num as number;
    this.studentList = data.list as object;
    this.maxPage = Math.ceil(this.num/this.perpage);
  }

  private getPageList(page: number) {
    this.page = (page < 1) ? 1 : (page > this.maxPage) ?  this.maxPage : page;
    this.getStudentList();
  }
}